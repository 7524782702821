<template>
  <v-container grid-list-xl>
    <p class="headline pt-3">Edit {{control.friendlyName}}</p>
    <validation-observer ref="obs" v-slot="{  }">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
            <v-row>
                <v-col sm="6" class="mt-3">
                <site-location-field
                    v-if="finishedLoading"
                    :siteId="siteId.toString()"
                    :selectedSiteLocationId="control.siteLocationId"
                    @sitelocationselected="siteLocationSelected"
                    ref="siteLocation"
                ></site-location-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                <v-autocomplete
                    :items="equipment"
                    item-value="equipmentId"
                    item-text="equipmentName"
                    clearable
                    v-model="selectedEquipment"
                    label="Equipment"
                    ref="equipment"
                    persistent-hint
                    outlined
                ></v-autocomplete>
            </v-col>
            </v-row>
          <confirm-dialog ref="confirmDelete" title="Delete Control?"></confirm-dialog>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="!isValidControl">Save</v-btn>
              <v-btn @click="handleCancelCrud" class="ml-5">Cancel</v-btn>
            </v-col>
           </v-row>
        </v-sheet>

        <v-row justify="center">
            <v-dialog v-model="dialog" max-width="600">
                <v-card>
                    <v-card-title class="headline">Validating {{type}}</v-card-title>
                    <v-card-text>
                        <v-stepper v-model="vSteps" vertical>
                        <v-stepper-step
                            :complete="vSteps > 1 && isValidSiteLocation"
                            step="1"
                            color="green">
                            Validating Site Location
                            <small v-if="vSteps > 1 && isValidSiteLocation === true">Site Location is valid</small>
                            <v-row style="width: 500px" v-if="vSteps > 1 && isValidSiteLocation === false">
                            <v-col class="pt-0" cols="2" md="9" sm="9">
                                <small v-if="vSteps > 2 && isValidSiteLocation === false">Must select site location</small>
                            </v-col>
                            <v-col cols="auto" md="3" sm="3">
                                <v-btn class="mt-n7" color="primary" title="Select a site location" @click="fixControlErrors(0)">Fix</v-btn>
                            </v-col>
                            </v-row>
                        </v-stepper-step>

                        <v-stepper-content step="1">
                            <v-progress-linear
                            indeterminate
                            color="green"
                            ></v-progress-linear>
                        </v-stepper-content>
                        
                        <v-stepper-step :complete="vSteps > 2 && isValidEquipment === true" step="2" color="green">
                            Validating Equipment
                            <small v-if="vSteps > 2 && isValidEquipment === true">{{
                            "Equipment is valid"
                            }}</small>
                            <v-row
                            style="width: 500px"
                            v-if="vSteps > 2 && isValidEquipment === false"
                            >
                            <v-col class="pt-0" cols="2" md="9" sm="9">
                                <small v-if="vSteps > 2">{{"Select an equipment"}}</small>
                            </v-col>
                            <v-col cols="auto" md="3" sm="3">
                                <v-btn
                                class="mt-n7"
                                color="primary"
                                title="Select an equipment"
                                @click="fixControlErrors(1)"
                                >Fix</v-btn
                                >
                            </v-col>
                            </v-row>
                        </v-stepper-step>
                        <v-stepper-content step="2">
                            <v-progress-linear indeterminate color="green"></v-progress-linear>
                        </v-stepper-content>
                        </v-stepper>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="cancelDialog">Cancel</v-btn>
                        <v-btn color="green darken-1"
                        :disabled="!isValidControl || !valid" text
                        @click="dismissDialog">
                            Continue
                        </v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
        </v-row>
        </v-form>
        </validation-observer>
    </v-container>
</template>

<script>
import goTo from "vuetify/es5/services/goto";
import { ValidationObserver } from "vee-validate";
import SiteLocationField from "@/modules/site/site_location/_components/SiteLocation";
import { mixin as VueTimers } from "vue-timers";
//import ValidatedTextField from "@/components/Fields/ValidatedTextField";
// import StepperValidate from "./StepperValidate.vue"
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import api from "../_api";
import siteLocationApi from "../../site/site_location/_api";
import equipmentApi from "../../site/equipment/_api";

export default {
  components: {
    "validation-observer": ValidationObserver,
    "site-location-field": SiteLocationField,
    "confirm-dialog": ConfirmDialog,
  },
  
  timers: {
    metaValidator: { time: 500, autostart: false, repeat: true },
  },
  
  mixins: [VueTimers],

  data() {
    return {
      finishedLoading: false,
      valid: true,
      vSteps: 0,
      control: {
        siteId: null,
        friendlyName: null,
        siteLocationId: null,
        equipmentId: null
      },
      deviceName: null,
      friendlyName: null,
      siteLocations: [],
      selectedSiteLocation: null,
      equipment: [],
      selectedEquipment: null,

      isValidSiteLocation: true,
      isValidEquipment: true,
      isValidControl: true,
      dialog: false,

      controlId: this.$route.params.controlId,
      type: this.$route.params.type
    };
  },

  async created() {
    this.siteId = this.$route.params.siteId;
    try {
        this.control = await api.getControlById(this.type, this.controlId);
        this.siteId = this.control.siteId;
        this.selectedSiteLocation = this.control.siteLocationId;
        this.siteLocations = await siteLocationApi.getSiteLocationsBySiteId(this.siteId);
        this.selectedEquipment = this.control.equipmentId;
        this.equipment = await equipmentApi.getEquipmentForSite(this.siteId);
        this.finishedLoading = true;
    } catch (error) {
        console.log(error);
    }
  },

  computed: {},

  methods: {

    async submit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }

      this.isValidating = false;
      this.validateControl();
    },

    siteLocationSelected(selectedSiteLocation) {
      this.selectedSiteLocation = selectedSiteLocation;
    },

    validateControl() {
      this.isValidating = true;
      this.isValidSiteLocation = false;
      this.isValidEquipment = false;
      this.dialog = true;
      this.$timer.start("metaValidator");
    },

    fixControlErrors(item) {
      switch (item) {
        case 0:
          this.$nextTick().then(() => {
            this.$refs.siteLocation.$refs.siteLocation.focus();
          });
          break;
        case 1:
          this.$nextTick().then(() => {
            this.$refs.equipment.focus();
          });
          break;
        default:
          break;
      }
    },

    cancelDialog() {
      this.isValidating = false;
      this.dialog = false;
      this.$timer.stop("metaValidator");
      this.vSteps = 0;
    },

    async dismissDialog() {
      this.cancelDialog();
      if (this.isValidControl) {
        goTo(0); //scroll up
        this.loading = true;
        try {
          var updatedControl = {
              deviceId: this.control.deviceId,
              siteLocationId: this.selectedSiteLocation.id,
              equipmentId: this.selectedEquipment
          }
          await api.updateControl(this.type, updatedControl);
          var pluralRoute = this.type +"s";
          if (pluralRoute === 'powercontrols') {
            pluralRoute = "power-controls"
          }
          this.$router.push({name: "ControlsIndex", params: {type: pluralRoute}});
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    metaValidator() {
      this.vSteps++;

      if (this.vSteps == 1) {
        this.isValidSiteLocation = this.selectedSiteLocation !== null;
      }
      if (this.vSteps == 2) {
        this.isValidEquipment = this.selectedEquipment !== null;
      }

      if (this.vSteps > 2) {
        this.$timer.stop("metaValidator");
        this.isValidating = false;
      }

      this.isValidControl =
        this.isValidSiteLocation &&
        this.isValidEquipment;
    },

     handleCancelCrud() {
      var pluralRoute = this.type +"s";
      if (pluralRoute === 'powercontrols') {
        pluralRoute = "power-controls"
      }

      this.$router.push({name: "ControlsIndex", params: {type: pluralRoute}});
    }
    
  },
};
</script>